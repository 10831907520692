import './info.css';
import React from 'react';
import { bio, credits, ultimateGames, statmuseBot, mvs, portfolio } from './exports';
import bbaldner from './img/me.png'
import ugImg from './img/Ultimate Games.png'
import botImg from './img/bot.jpg'
import mvsImg from './img/mvs.jpg'
import pImg from './img/port.gif'

function Info() {
    const addLinks = (t) => {
        let text = t;
        let index = text.indexOf("<link>");
        while(index != -1)
        {
            let ind1 = text.indexOf("</link>")
            let link = text.substring(index + 6, ind1 - 1);
            text = text.substring(0, index - 1) + "{" + (<a src={link}>link</a>) + "}" + text.substring(ind1 + 8, text.length -1);
            index = text.indexOf("<link>");
        }
        return text;
    }
    return (<div className='infomain'>
        <h1 style={{"width": "calc(100% - 50px)", "borderBottom": "1px solid black", "margin": "auto", "paddingLeft": "10px"}}>Brian Baldner</h1>
        <div className='biosec'>
            <p>{bio}</p>
            <img src={bbaldner} />
        </div>
        <h1 style={{"width": "calc(100% - 50px)", "borderBottom": "1px solid black", "margin": "auto", "paddingLeft": "10px", "fontSize": "25px"}}>Projects</h1>
        <div className='biosec'>
            <p><h1 style={{"margin": "auto", "paddingLeft": "00px", "paddingTop": "10px", "marginBottom": "3px", "fontSize": "20px"}}>
                Ultimate Games
                </h1>
                <h1 style={{"margin": "auto", "paddingLeft": "00px", "paddingTop": "0px", "marginBottom": "3px", "fontSize": "15px", "color": "#777777"}}>
                December 2018
                </h1>
                {ultimateGames}</p>
            <img src={ugImg} />
        </div>

        <div className='biosec'>
        <p><h1 style={{"margin": "auto", "paddingLeft": "00px", "paddingTop": "00px", "marginBottom": "3px", "fontSize": "20px"}}>
                StatMuse Bot on Real
                </h1>
                <h1 style={{"margin": "auto", "paddingLeft": "00px", "paddingTop": "0px", "marginBottom": "3px", "fontSize": "15px", "color": "#777777"}}>
                December 2022
                </h1>
                {statmuseBot}</p>
            <img src={botImg} />
        </div>

        <div className='biosec'>
        <p><h1 style={{"margin": "auto", "paddingLeft": "00px", "paddingTop": "00px", "marginBottom": "3px", "fontSize": "20px"}}>
                MultiversusTracker.com
                </h1>
                <h1 style={{"margin": "auto", "paddingLeft": "00px", "paddingTop": "0px", "marginBottom": "3px", "fontSize": "15px", "color": "#777777"}}>
                July 2022
                </h1>
                {portfolio}</p>
            <img src={pImg} />
        </div>

        <h1 style={{"width": "calc(100% - 50px)", "borderBottom": "1px solid black", "margin": "auto", "paddingLeft": "10px", "fontSize": "25px"}}>Links</h1>
        <a style={{"margin": "0px 35px"}} target="_blank" href="https://github.com/brianbaldner">Github</a>
        <br />
        <a style={{"margin": "0px 35px"}} target="_blank" href="mailto:brian@brianbaldner.com">Email</a>
        
        <h1 style={{"width": "calc(100% - 50px)", "borderBottom": "1px solid black", "margin": "auto", "paddingLeft": "10px", "fontSize": "25px"}}>Credits</h1>
        <p style={{"margin": "0px 35px"}} dangerouslySetInnerHTML={{__html: credits}}></p>
    </div>);
  }
export default Info;