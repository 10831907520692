export const bio= "\tBrian Baldner is a high school programmer. He creates personal projects in his free time, and is working towards a career in computer science. He also plays volleyball. Baldner started programming in 7th grade when he created Ultimate Games, a browser game website for him and his friends. This website soon spread through the whole school, and soon he was running a site with almost a thousand users. This led to him picking up C# and Unity, when he created Havok Car (and other games that can be forgotten about). As Ultimate Games died with the end of flash games and as people moved on to new sites, Baldner kept learning C#, and programmed his own tools, apps, and games. While most projects are not published and public, his lengthy career with programming has turned him into a expert full stack developer. One of his obsolete projects is multiversustracker.com, which gained 37,000 unique visitors in the first 60 days after launch. He is proficient in C#, Javascript, Java, and more.";

export const credits= "I got my inspiration for this from Henry Heffernan. Check his version out at <a target=\"_blank\" href=\"https://henryheffernan.com/\">henryheffernan.com</a><br>The design of this page was made to resemble <a target=\"_blank\" href=\"https://wikipedia.org/\">wikipedia.org</a><br>The desktop uses images from Microsoft Windows";

export const ultimateGames = "Ultimate Games was my first project as a programmer. It was a website that had a ton of games on flash games that my friends would play. I made it with pure HTML and CSS."

export const statmuseBot = "One of my favorite apps since it released has been Real. It is a sports app that lets users comment on plays and games. I wanted to create a bot like the ones on Discord that would respond to any mention on any play. After days of learning the authentication, data structures, and protocol of the Real servers, I was able to create a bot account using C# that would answer questions using StatMuse, another one of my favorite services. It got the attention of the creators of Real, became one of the most popular accounts, and I believe it is still the only bot on the whole app. You can find the bot on the Real Sports app at @statmusebot."

export const mvs = "When Multiversus released, a fighting game made by Warner Bros, I saw an oppertunity to be the first website to show player stats. I made a React site with a C# and became the first to document the API of the game. When multiversustracker.com released, it was the first Mulitversus tracker before sites like tracker.com. It made it to the top of Google and reached 37 thousand unique visitors in its first 60 days. The website is no longer running as the game has been taken down until it's full release."

export const portfolio = "When I wanted to make a place to show my projects, I wanted something that was unique. I learned Three.JS and made a 3D room with some of my own photography, a clock, and a laptop where you could see my projects. The laptop OS was made in React and made to look like Windows."